import React, { useState } from 'react';
import { array, arrayOf, bool, func, shape, string, oneOf, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { FormattedMessage, intlShape, useIntl } from '../../util/reactIntl';
import {
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_CLOSED,
  SCHEMA_TYPE_MULTI_ENUM,
  SCHEMA_TYPE_TEXT,
  propTypes,
} from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { getIsOwnerClient, getIsOwnerConsultant } from '../../util/gig';
import { getIsClient, getIsConsultant } from '../../util/user';
import { convertMoneyToNumber } from '../../util/currency';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { richText } from '../../util/richText';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/ui.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
const { types } = require('sharetribe-flex-sdk');
const { Money } = types;

import {
  H4,
  Page,
  NamedLink,
  NamedRedirect,
  Footer,
  OrderPanel,
  LayoutSingleColumn,
  Button,
  LineDivider,
  ReviewRating,
  AsideContainerColumn,
  AsideCardWhite,
} from '../../components';

import TopbarContainer from '../TopbarContainer/TopbarContainer';
import NotFoundPage from '../NotFoundPage/NotFoundPage';

import {
  sendInquiry,
  setInitialValues,
  fetchTimeSlots,
  fetchTransactionLineItems,
} from './ListingPage.duck';

import {
  LoadingPage,
  ErrorPage,
  priceData,
  listingImages,
  handleContactUser,
  handleSubmitInquiry,
  handleSubmit,
} from './ListingPage.shared';
import ActionBarMaybe from './ActionBarMaybe';
import SectionTextMaybe from './SectionTextMaybe';
import SectionDetailsMaybe from './SectionDetailsMaybe';
import SectionMultiEnumMaybe from './SectionMultiEnumMaybe';
import SectionReviews from './SectionReviews';
import SectionAuthorMaybe from './SectionAuthorMaybe';
import SectionMapMaybe from './SectionMapMaybe';
import SectionGallery from './SectionGallery';

import css from './ListingPage.module.css';
import AuthorCard from '../../components/AuthorCard/AuthorCard';
import Chip from '../../components/Chip/Chip';
import FileUploadDropField from '../../components/FileUploadDropField/FileUploadDropField';
import FileUploadView from '../../components/FileUploadView/FileUploadView';
import WalletIcon from './icons/WalletIcon';
import CalendarIcon from './icons/CalendarIcon';
import CardIcon from './icons/CardIcon';
import PostedIcon from './icons/PostedIcon';
import UserIcon from './icons/UserIcon';
import PhoneIcon from './icons/PhoneIcon';
import PaymentIcon from './icons/PaymentIcon';
import StatisticIcon from './icons/StatisticIcon';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID } = sdkTypes;

export const ListingPageComponent = props => {
  const [inquiryModalOpen, setInquiryModalOpen] = useState(
    props.inquiryModalOpenForListingId === props.params.id
  );

  const {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    intl,
    onManageDisableScrolling,
    params: rawParams,
    location,
    scrollingDisabled,
    showListingError,
    reviews,
    fetchReviewsError,
    sendInquiryInProgress,
    sendInquiryError,
    monthlyTimeSlots,
    onFetchTimeSlots,
    listingConfig: listingConfigProp,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    history,
    callSetInitialValues,
    onSendInquiry,
    onInitializeCardPaymentData,
    config,
    routeConfiguration,
  } = props;

  // prop override makes testing a bit easier
  // TODO: improve this when updating test setup
  const listingConfig = listingConfigProp || config.listing;
  const listingId = new UUID(rawParams.id);
  const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
  const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
  const currentListing =
    isPendingApprovalVariant || isDraftVariant
      ? ensureOwnListing(getOwnListing(listingId))
      : ensureListing(getListing(listingId));

  const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
  const params = { slug: listingSlug, ...rawParams };

  const listingPathParamType = isDraftVariant
    ? LISTING_PAGE_PARAM_TYPE_DRAFT
    : LISTING_PAGE_PARAM_TYPE_EDIT;
  const listingTab = isDraftVariant ? 'photos' : 'details';

  const isApproved =
    currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

  const pendingIsApproved = isPendingApprovalVariant && isApproved;

  // If a /pending-approval URL is shared, the UI requires
  // authentication and attempts to fetch the listing from own
  // listings. This will fail with 403 Forbidden if the author is
  // another user. We use this information to try to fetch the
  // public listing.
  const pendingOtherUsersListing =
    (isPendingApprovalVariant || isDraftVariant) &&
    showListingError &&
    showListingError.status === 403;
  const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

  if (shouldShowPublicListingPage) {
    return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
  }

  const topbar = <TopbarContainer isDark={true} />;

  if (showListingError && showListingError.status === 404) {
    // 404 listing not found
    return <NotFoundPage />;
  } else if (showListingError) {
    // Other error in fetching listing
    return <ErrorPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  } else if (!currentListing.id) {
    // Still loading the listing
    return <LoadingPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  }

  const {
    id,
    description = '',
    geolocation = null,
    price = null,
    title = '',
    publicData = {},
    metadata = {},
  } = currentListing.attributes;

  const richTitle = (
    <span>
      {richText(title, {
        longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
        longWordClass: css.longWord,
      })}
    </span>
  );

  const authorAvailable = currentListing && currentListing.author;
  const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
  const isOwnListing =
    userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;

  const currentAuthor = authorAvailable ? currentListing.author : null;
  const ensuredAuthor = ensureUser(currentAuthor);

  // When user is banned or deleted the listing is also deleted.
  // Because listing can be never showed with banned or deleted user we don't have to provide
  // banned or deleted display names for the function
  const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

  const isOwnerClient = getIsOwnerClient(currentListing);
  const isOwnerConsultant = getIsOwnerConsultant(currentListing);

  const curentUserIsClient = getIsClient(currentUser);
  const curentUserIsConsultant = getIsConsultant(currentUser);



  const { formattedPrice } = priceData(price, config.currency, intl);

  const commonParams = { params, history, routes: routeConfiguration };
  const onContactUser = handleContactUser({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    location,
    setInitialValues,
    setInquiryModalOpen,
  });
  const onSubmitInquiry = handleSubmitInquiry({
    ...commonParams,
    getListing,
    onSendInquiry,
    setInquiryModalOpen,
  });
  const onSubmit = handleSubmit({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    getListing,
    onInitializeCardPaymentData,
  });

  const handleOrderSubmit = values => {
    const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
    if (isOwnListing || isCurrentlyClosed) {
      window.scrollTo(0, 0);
    } else {
      onSubmit(values);
    }
  };

  const facebookImages = listingImages(currentListing, 'facebook');
  const twitterImages = listingImages(currentListing, 'twitter');
  const schemaImages = listingImages(
    currentListing,
    `${config.layout.listingImage.variantPrefix}-2x`
  ).map(img => img.url);
  const marketplaceName = config.marketplaceName;
  const schemaTitle = intl.formatMessage(
    { id: 'ListingPage.schemaTitle' },
    { title, price: formattedPrice, marketplaceName }
  );
  // You could add reviews, sku, etc. into page schema
  // Read more about product schema
  // https://developers.google.com/search/docs/advanced/structured-data/product
  const productURL = `${config.marketplaceRootURL}${location.pathname}${location.search}${location.hash}`;
  const schemaPriceMaybe = price
    ? {
      price: intl.formatNumber(convertMoneyToNumber(price), {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      priceCurrency: price.currency,
    }
    : {};
  const currentStock = currentListing.currentStock?.attributes?.quantity || 0;
  const schemaAvailability =
    currentStock > 0 ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock';

  const createFilterOptions = options => options.map(o => ({ key: `${o.option}`, label: o.label }));

  const getPriceString = moneyValue => {
    if (!moneyValue) return '-';

    return priceData(moneyValue, useConfiguration().currency, intl).formattedPrice;
  };

  const totalGigsPosted = 0;
  const totalGigsActive = 0;
  const hireRate = 0; //to do calculate all this or store it on user profile

  const formattedDate = ensuredAuthor.attributes?.createdAt?.toLocaleDateString('en-GB');
  const formattedTime = ensuredAuthor.attributes?.createdAt?.toLocaleTimeString('en-GB', {
    hour: '2-digit',
    minute: '2-digit',
  });


  const country =
    isOwnerClient ?
      ensuredAuthor?.attributes?.profile?.publicData?.country + "," :
      isOwnerConsultant ?
        ensuredAuthor?.attributes?.profile?.publicData?.address?.country + "," :
        null;



  const city =
    isOwnerClient ?
      ensuredAuthor?.attributes?.profile?.publicData?.city + "," :
      isOwnerConsultant ?
        ensuredAuthor?.attributes?.profile?.publicData?.address?.city + "," :
        null;

  return (
    <Page
      className={css.root}
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      author={authorDisplayName}
      description={description}
      facebookImages={facebookImages}
      twitterImages={twitterImages}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'Product',
        description: description,
        name: schemaTitle,
        image: schemaImages,
        offers: {
          '@type': 'Offer',
          url: productURL,
          ...schemaPriceMaybe,
          availability: schemaAvailability,
        },
      }}
    >
      <LayoutSingleColumn
        topbar={topbar}
        footer={<Footer />}
        mainColumnClassName={css.rootBackground}
      >
        <div className={css.horizontalWrapper}>
          <AsideContainerColumn>
            <AsideCardWhite>
              <p className={css.asideMainTitle}>
                {isOwnerClient && <FormattedMessage id="ListingPage.aside.aboutClient" />}
                {isOwnerConsultant && <FormattedMessage id="ListingPage.aside.aboutConsultant" />}
              </p>

              {/* {ensuredAuthor.attributes.profile?.publicData?.rating &&
                <div className={css.ratingRow}>
                  <ReviewRating rating={4} reviewStarClassName={css.stars} />

                  <p className={css.ratingHighlight}>
                    <FormattedMessage
                      id="AuthorCard.ratingsCount"
                      values={{
                        ratingsCount: ensuredAuthor.attributes.profile?.publicData?.rating
                          ? ensuredAuthor.attributes.profile?.publicData?.rating
                          : // : 0,
                          '1.6K',
                      }}
                    />
                  </p>
                </div>
              } */}
              {totalGigsPosted > 0 && hireRate > 0 &&
                <p className={css.asideMainText}>
                  {totalGigsPosted > 0 &&
                    <span className={css.asideMainText}>
                      {totalGigsPosted}
                      <FormattedMessage id="ListingPage.aside.gigsPosted" />
                    </span>
                  }
                  <br />
                  {hireRate > 0 &&
                    <span className={css.asideMainTextHighlight}>
                      {hireRate}
                      <FormattedMessage id="ListingPage.aside.hireRate" />
                      {totalGigsActive}
                      <FormattedMessage id="ListingPage.aside.activeGigs" />
                    </span>
                  }
                </p>
              }

              <p className={css.asideMainText}>
                <FormattedMessage id="ListingPage.aside.memberSince" />
                {formattedDate}
              </p>

              <p className={css.asideMainText}>
                {country} {city}
                {/* {formattedTime} */}
              </p>
              <div className={css.iconsContainer}>
                <CardIcon />
                <PostedIcon />
                <UserIcon />
                <PhoneIcon />
              </div>

              <LineDivider />
              <NamedLink
                name="ProfilePage"
                params={{ id: currentListing?.author?.id?.uuid }}
                className={css.link}
              >
                <FormattedMessage id={'ListingPage.aside.viewProfile'} />
              </NamedLink>
            </AsideCardWhite>

            <AsideCardWhite>
              <p className={css.asideTitle}>
                <FormattedMessage id="ListingPage.aside.similarGigs" />
              </p>
              <div>{/* need to add slides */}</div>
              <LineDivider />
              <NamedLink name="LoginPage" className={css.buttonLink}>
                <FormattedMessage id={'ListingPage.aside.viewGig'} />
              </NamedLink>
            </AsideCardWhite>

            <AsideCardWhite>
              <p className={css.asideTitle}>
                <FormattedMessage id="ListingPage.aside.protectOurUsers" />
              </p>
              <p className={css.asideText}>
                <FormattedMessage id="ListingPage.aside.protectOurUsersContent" />
              </p>
              <LineDivider />
              <NamedLink name="LoginPage" className={css.buttonLink}>
                <FormattedMessage id={'ListingPage.aside.learnMore'} />
              </NamedLink>
            </AsideCardWhite>

            {/* <AsideCardWhite>
              <p className={css.asideText}>
                <FormattedMessage id="ListingPage.aside.joinGigExecs" />
              </p>
              <LineDivider />
              <NamedLink name="LoginPage" className={css.buttonLink}>
                <FormattedMessage id={'ListingPage.aside.findOutMore'} />
              </NamedLink>
            </AsideCardWhite> */}
          </AsideContainerColumn>
          <div className={css.layoutWrapperMain} role="main">
            <div className={css.card}>
              <AuthorCard
                author={ensuredAuthor}
                currentUser={currentUser}
                onContactUser={onContactUser}
                postedDate={currentListing?.attributes?.createdAt}
                intl={intl}
              />

              <LineDivider />

              <p className={css.title}>{title}</p>

              <p className={css.info}>{description}</p>

              <LineDivider customStyle={{ marginTop: 28 }} />

              <p className={css.title}>
                <FormattedMessage id="ListingPage.gigScope" />
              </p>

              <div className={css.rowSpaced} style={publicData?.milestones && { marginBottom: 36 }}>

                {isOwnerClient && (
                  <div className={css.row}>
                    <WalletIcon />
                    <p className={css.subTitle}>
                      <FormattedMessage id="ListingPage.budgetLabel" />
                    </p>
                    <div className={css.bar} />
                    <p className={css.scope}>{formattedPrice}</p>
                  </div>
                )}
                {isOwnerConsultant && (
                  <div className={css.row}>
                    <PaymentIcon />
                    <p className={css.subTitle}>
                      <FormattedMessage id="ListingPage.hourlyLabel" />
                    </p>
                    <div className={css.bar} />
                    <p className={css.scope}>{formattedPrice}</p>
                  </div>
                )}

                {publicData?.milestones && (
                  <div className={css.row}>
                    <StatisticIcon />
                    <p className={css.subTitle}>
                      <FormattedMessage id="ListingPage.milestonesLabel" />
                    </p>
                    <div className={css.bar} />
                    <p className={css.scope}>
                      {publicData?.milestones?.length}
                    </p>
                  </div>
                )}

                <div className={css.row}>
                  <CalendarIcon />
                  <p className={css.subTitle}>
                    <FormattedMessage id="ListingPage.durationLabel" />
                  </p>
                  <div className={css.bar} />
                  <p className={css.scope}>
                    <FormattedMessage id={'gigDurations.' + publicData?.duration} />
                  </p>
                </div>
              </div>


              {publicData?.milestones && (
                <>
                  <p className={css.subTitle}>
                    <FormattedMessage id="ListingPage.milestonesLabel" />
                  </p>
                  <ol className={css.milestonesList}>
                    {publicData.milestones?.map((value, index) => {
                      return (
                        <li key={index}>
                          <div className={css.row} >
                            <p className={css.info} style={{ flexGrow: 1 }}>
                              {value.milestone}
                            </p>
                            <p className={css.info}>{(value.amount && value.currency) &&
                              getPriceString(new Money(value.amount, value.currency))}
                              {value.percentage && value.percentage + '%'}</p>

                            {/* <p className={css.info} style={{ flexGrow: 1 }}>
                              {value?.amount
                                ? getPriceString(new Money(value.amount, value.currency)) +
                                ' ' +
                                intl.formatMessage({ id: 'ListingPage.or' }) +
                                ' ' +
                                (value.percentage ? value.percentage : 'N/A') +
                                '%'
                                : 'N/A'}
                            </p> */}
                          </div>
                        </li>
                      );
                    })}
                  </ol>
                </>
              )}

              <LineDivider customStyle={{ marginTop: 28 }} />

              <div className={css.column}>
                <p className={css.title}>
                  <FormattedMessage id="ListingPage.skillsRequired" />
                </p>

                <div className={css.multiLine}>
                  {publicData?.skills?.map((value, index) => {
                    return <Chip value={value} key={index} />;
                  })}
                </div>
              </div>

              <LineDivider customStyle={{ marginTop: 28 }} />

              {/* <div className={css.column}>
                <p className={css.title}>
                  <FormattedMessage id="ListingPage.attachments" />
                </p>

                <div className={css.multiLine}>
                  {publicData?.attachments?.map((value, index) => {
                    return (
                      <FileUploadView
                        key={index}
                        id={`attachment`}
                        name={`attachment`}
                        value={value}
                      />
                    );
                  })}
                </div>
              </div>

              <LineDivider /> */}

              {publicData?.questions && (
                <>
                  <div className={css.column}>
                    <p className={css.title}>
                      <FormattedMessage id="ListingPage.screeningQuestions" />
                    </p>

                    <div className={css.column} style={{ gap: '24px', alignItems: 'start' }}>
                      {publicData?.questions?.map((value, index) => {
                        return (
                          <p key={index} className={css.question}>
                            {value}
                          </p>
                        );
                      })}
                    </div>
                  </div>
                  <LineDivider customStyle={{ marginTop: 28 }} />
                </>
              )}

              {!isOwnListing && (
                <>
                  <p className={css.terms}>
                    <FormattedMessage id="ListingPage.agree1" />

                    <span className={css.termsHighlight}>
                      <NamedLink name="TermsOfServicePage" className={css.termsHighlight}>
                        <FormattedMessage id="ListingPage.agree2" />
                      </NamedLink>
                    </span>

                    <FormattedMessage id="ListingPage.agree3" />

                    <span className={css.termsHighlight}>
                      <NamedLink name="PrivacyPolicyPage" className={css.termsHighlight}>
                        <FormattedMessage id="ListingPage.agree4" />
                      </NamedLink>
                    </span>

                    <FormattedMessage id="ListingPage.agree5" />
                  </p>

                  <div
                    className={css.row}
                    style={{ gap: '16px', width: '100%', justifyContent: 'end' }}
                  >
                    <Button
                      className={css.messageButton}
                      onClick={e => {
                        if (handleSubmit) {
                          e.stopPropagation();
                          e.preventDefault();
                        }
                      }}
                    >
                      <FormattedMessage id="ListingPage.message" />
                    </Button>

                    {curentUserIsClient && (
                      <Button
                        className={css.button}
                        onClick={e => {
                          if (handleSubmit) {
                            e.stopPropagation();
                            e.preventDefault();

                            window.location = '/b/' + currentListing.id.uuid;
                          }
                        }}
                      >
                        <FormattedMessage id="ListingPage.purchaseGig" />
                      </Button>
                    )}
                    {curentUserIsConsultant && (
                      <Button
                        className={css.button}
                        onClick={e => {
                          if (handleSubmit) {
                            e.stopPropagation();
                            e.preventDefault();

                            window.location = '/b/' + currentListing.id.uuid;
                          }
                        }}
                      >
                        <FormattedMessage id="ListingPage.bidForGig" />
                      </Button>
                    )}
                  </div>
                </>
              )}
              {/* <SectionAuthorMaybe
              title={title}
              listing={currentListing}
              authorDisplayName={authorDisplayName}
              onContactUser={onContactUser}
              isInquiryModalOpen={isAuthenticated && inquiryModalOpen}
              onCloseInquiryModal={() => setInquiryModalOpen(false)}
              sendInquiryError={sendInquiryError}
              sendInquiryInProgress={sendInquiryInProgress}
              onSubmitInquiry={onSubmitInquiry}
              currentUser={currentUser}
              onManageDisableScrolling={onManageDisableScrolling}
            /> */}
            </div>
          </div>
        </div>

        {/* <div className={css.contentWrapperForProductLayout}>
          <div className={css.mainColumnForProductLayout}> */}
        {/* {currentListing.id ? (
              <ActionBarMaybe
                className={css.actionBarForProductLayout}
                isOwnListing={isOwnListing}
                listing={currentListing}
                editParams={{
                  id: listingId.uuid,
                  slug: listingSlug,
                  type: listingPathParamType,
                  tab: listingTab,
                }}
              />
            ) : null} */}
        {/* <SectionGallery
              listing={currentListing}
              variantPrefix={config.layout.listingImage.variantPrefix}
            /> */}

        {/* <div className={css.mobileHeading}>
              <H4 as="h1" className={css.orderPanelTitle}>
                <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
              </H4>
            </div> */}
        {/* <SectionTextMaybe text={description} showAsIngress /> */}
        {/* <SectionDetailsMaybe
              publicData={publicData}
              metadata={metadata}
              listingConfig={listingConfig}
              intl={intl}
            /> */}
        {/* {listingConfig.listingFields.reduce((pickedElements, config) => {
              const { key, enumOptions, scope = 'public' } = config;
              const value =
                scope === 'public' ? publicData[key] : scope === 'metadata' ? metadata[key] : null;
              const hasValue = value !== null;
              return hasValue && config.schemaType === SCHEMA_TYPE_MULTI_ENUM
                ? [
                  ...pickedElements,
                  <SectionMultiEnumMaybe
                    key={key}
                    heading={config?.showConfig?.label}
                    options={createFilterOptions(enumOptions)}
                    selectedOptions={value}
                  />,
                ]
                : hasValue && config.schemaType === SCHEMA_TYPE_TEXT
                  ? [
                    ...pickedElements,
                    <SectionTextMaybe key={key} heading={config?.showConfig?.label} text={value} />,
                  ]
                  : pickedElements;
            }, [])} */}

        {/* <SectionMapMaybe
              geolocation={geolocation}
              publicData={publicData}
              listingId={currentListing.id}
              mapsConfig={config.maps}
            /> */}
        {/* <SectionReviews reviews={reviews} fetchReviewsError={fetchReviewsError} /> */}
        {/* <SectionAuthorMaybe
              title={title}
              listing={currentListing}
              authorDisplayName={authorDisplayName}
              onContactUser={onContactUser}
              isInquiryModalOpen={isAuthenticated && inquiryModalOpen}
              onCloseInquiryModal={() => setInquiryModalOpen(false)}
              sendInquiryError={sendInquiryError}
              sendInquiryInProgress={sendInquiryInProgress}
              onSubmitInquiry={onSubmitInquiry}
              currentUser={currentUser}
              onManageDisableScrolling={onManageDisableScrolling}
            /> */}
        {/* </div> */}
        {/* <div className={css.orderColumnForProductLayout}>
            <OrderPanel
              className={css.productOrderPanel}
              listing={currentListing}
              isOwnListing={isOwnListing}
              onSubmit={handleOrderSubmit}
              authorLink={
                <NamedLink
                  className={css.authorNameLink}
                  name="ListingPage"
                  params={params}
                  to={{ hash: '#author' }}
                >
                  {authorDisplayName}
                </NamedLink>
              }
              title={<FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />}
              titleDesktop={
                <H4 as="h1" className={css.orderPanelTitle}>
                  <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
                </H4>
              }
              author={ensuredAuthor}
              onManageDisableScrolling={onManageDisableScrolling}
              onContactUser={onContactUser}
              monthlyTimeSlots={monthlyTimeSlots}
              onFetchTimeSlots={onFetchTimeSlots}
              onFetchTransactionLineItems={onFetchTransactionLineItems}
              lineItems={lineItems}
              fetchLineItemsInProgress={fetchLineItemsInProgress}
              fetchLineItemsError={fetchLineItemsError}
              marketplaceCurrency={config.currency}
              dayCountAvailableForBooking={config.stripe.dayCountAvailableForBooking}
              marketplaceName={config.marketplaceName}
            />
          </div> */}
        {/* </div> */}
      </LayoutSingleColumn>
    </Page >
  );
};

ListingPageComponent.defaultProps = {
  currentUser: null,
  inquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: null,
  sendInquiryError: null,
  listingConfig: null,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
  // from useLocation
  location: shape({
    search: string,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,
  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  inquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  monthlyTimeSlots: object,
  // monthlyTimeSlots could be something like:
  // monthlyTimeSlots: {
  //   '2019-11': {
  //     timeSlots: [],
  //     fetchTimeSlotsInProgress: false,
  //     fetchTimeSlotsError: null,
  //   }
  // }
  sendInquiryInProgress: bool.isRequired,
  sendInquiryError: propTypes.error,
  onSendInquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  listingConfig: object,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const EnhancedListingPage = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  return (
    <ListingPageComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      location={location}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    sendInquiryInProgress,
    sendInquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    inquiryModalOpenForListingId,
  } = state.ListingPage;
  const { currentUser } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    inquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendInquiryInProgress,
    sendInquiryError,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: params => dispatch(fetchTransactionLineItems(params)),
  onSendInquiry: (listing, message) => dispatch(sendInquiry(listing, message)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EnhancedListingPage);

export default ListingPage;
